import React from 'react';

export function BookingComplete ({ booking, space}) {
  return (
    <div className='card-footer'>
      <div className='toast toast-success'>
        <span>Booking complete!</span>
        <i className='icon icon-check btn-clear float-right'></i>
        <br />
        <span>You're good to go, thanks for using ParKing</span>
        <br />
        <span>{booking.start === '00:00' && booking.end === '23:59' ? 'All day' : `${booking.start}-${booking.end}`} on {booking.date}</span>
      </div>
    </div>
  )
}
