import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import api from '../lib/api.js';
import auth from '../lib/auth.js';
import logo from '../img/crown.png';
import LoginStyles from '../styles/Login.module.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: false,
      loading: false,
      success: false
    };
  }
  handleChange = (e) => this.setState({[e.target.name]: e.target.value})
  submitLoginForm = async (e) => {
    e.preventDefault()
    this.setState({loading: !this.state.loading})
    let { email, password } = this.state
    let login = await this.login(email.trim().toLowerCase(), password)
    if (login) {
      this.props.history.push('/map', { from: 'login' })
    }
  }
  login = async (email, password) => {
    try {
      let { token, user, error } = await api('/auth/jwt', { email, password })
      if (error) throw new Error(error.message)
      if (!user) {
        alert(`Love that you're keen, but no existing accounts were found. Please sign up first.`)
        this.setState({ loading: !this.state.loading })
        return false
      }
      auth.setToken(token)
      auth.setUserInfo(user)
      return true
    } catch (error) {
      this.setState({ loading: !this.state.loading })
      alert(`Oh no! Login failed. ${error}`)
      return false
    }
  }

  render() {
    return (
      <div id='loginView' className={`container hero ${LoginStyles.heroLg} trovaBlueBG ${LoginStyles.login}`}>
        <div className='columns'>
          <div className='column col-md-12 col-8 col-mx-auto'>
            <div className='container grid-lg'>
              <div className='hero-body'>
                <div className={`card ${LoginStyles.card}`}>
                  <div className='card-body'>
                    <form className='form-group form-horizontal' onSubmit={this.submitLoginForm}>
                      <div className='card-header'>
                        <Link to='/welcome'>
                          <img src={logo} alt='parKing logo' className={LoginStyles.loginLogo} />
                        </Link>
                      </div>

                      <div className='form-group'>
                        <div className='col-3 col-sm-12'>
                          <label className={`form-label ${LoginStyles.loginLabel}`}  htmlFor='login-email'>Email</label>
                        </div>
                        <div className={`col-8 col-sm-12 col-ml-auto ${LoginStyles.loginInputParent}`}>
                          <input className={LoginStyles.loginInput} id='login-email' name='email' value={this.state.email} type='email' onChange={this.handleChange} />
                        </div>
                      </div>

                      <div className='form-group'>
                        <div className='col-3 col-sm-12'>
                          <label className={`form-label ${LoginStyles.loginLabel}`}  htmlFor='login-password'>Password</label>
                        </div>
                        <div className={`col-8 col-sm-12 col-ml-auto ${LoginStyles.loginInputParent}`}>
                          <input className={LoginStyles.loginInput} id='login-password' name='password' value={this.state.password} type='password' onChange={this.handleChange} />
                        </div>
                      </div>

                      <button type='submit' className={`btn btn-primary ${this.state.loading ? 'loading' : ''} ${LoginStyles.loginBtn}`}>Login</button>
                      <Link className={`btn btn-link trovaBlue float-right ${LoginStyles.toRegisterBtn}`} to='/register'>Register</Link>
                      <p style={{marginBottom: 0, marginTop: '2em', textAlign: 'center'}}>
                        <Link className={`btn btn-link trovaBlue ${LoginStyles.registerBtn}`} to='/reset'>Reset Password</Link>
                      </p>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
