import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import api from '../lib/api';
import CountUp from '../components/CountUp';
import TopNav from '../components/TopNav';
import Schools from '../components/Schools';
import logo from '../img/crown.png';
import logoWhite from '../img/crown.png';

import mock from '../img/screenMockPK.png';
import list from '../img/phoneTapBlack.png';
import approve from '../img/approve.png';
import money from '../img/money.png';

import LandingStyles from '../styles/Landing.module.css';
import A from '../styles/Animate.module.css';
import Bulma from '../styles/Bulma.module.css';

function Landing (props) {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [showWhy, toggleWhy] = useState(false)
  const [showHow, toggleHow] = useState(false)
  const [isLoading, toggleLoading] = useState(false)
  const [refNumbers, numbersInView] = useInView({ threshold: 0, triggerOnce: true })
  const [refWhy, whyInView] = useInView({ threshold: 0.15, triggerOnce: true })
  const [refHow, howInView] = useInView({ threshold: 0.15, triggerOnce: true })

  const handleSubmit = async (event) => {
    event.preventDefault()
    toggleLoading(true)
    // await api('/auth/registerInterest', { email, name, role })
    // redirect to register, with state as email
    props.history.push('/register', { email, name, role })
  }
  useEffect(() => {
    if (whyInView) { toggleWhy(true) }
    if (howInView) { toggleHow(true) }
  }, [whyInView, howInView])

  return (
    <div className={`homeBody smoothScroll ${LandingStyles['tk-museo-sans']}`}>
      <TopNav Bulma={Bulma} />
      {/* Landing */}
      <section className={`${Bulma.hero} ${Bulma['is-fullheight-with-navbar']}`}>
        <div className={`${Bulma['hero-body']}`}>
          <div className={`${Bulma['container']}`}>
            <div className={`${Bulma.columns} ${Bulma.level}`}>
              <div className={`${Bulma.column} ${Bulma['is-8']}`}>
                <p className={`${LandingStyles.landingTitle} ${Bulma['title']} ${Bulma['is-spaced']} ${A.slideInLeft} ${A.animated} ${Bulma['is-2']} ${Bulma['is-large']}`}>
                  {/* Vancouver's new park share hotness */}
                  Parking Reimagined
                </p>
                <p className={`${Bulma.subtitle} ${Bulma['is-6']} ${A.slideInLeft} ${A.animated}`}>What if parking was fun?</p>
                <p className={`${Bulma.subtitle} ${Bulma['is-4']} ${A.slideInLeft} ${A.animated} ${A['delay-2s']}`}>
                  Now you can save money on parking & earn money while you're out, with <img className='copyLogo' src={logo} alt='ParKing' />
                </p>
                <form onSubmit={handleSubmit} className={`${A.animated} ${A.slideInLeft} ${A['delay-3s']}`}>
                  <div className='input-group' style={{maxWidth: '640px'}}>
                    <input type='email' className='form-input' value={email} placeholder='Email address' onChange={(e) => setEmail(e.target.value)} />
                    <button type='submit' className={`trovaBlueBG btn btn-primary input-group-btn ${isLoading ? 'loading' : ''}`}>Sign Up</button>
                  </div>
                </form>
              </div>
              <div className={`${Bulma['has-text-centered']} ${Bulma.column} ${Bulma['level-item']} ${A.slideInRight} ${A.animated} ${A['delay-3s']}`}>
                <img src={mock} alt='mock up of parKing running on a phone' />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Service 3/3 */}
      <section ref={refWhy} className={`${Bulma.section} ${showWhy ? A.animated : ''} ${showWhy ? A.fadeIn : ''}`} id='why' style={{paddingBottom: '4rem'}}>
        <div className={Bulma.container} style={{marginBottom: '4rem'}}>
          <p style={{paddingBottom: '2rem'}} className={`${Bulma.title} ${Bulma['is-size-2']} ${Bulma['has-text-centered']}`}>Meet <img className='copyLogo logoInTitle' src={logo} alt='ParKing' /></p>
          <div className={`${Bulma.columns} ${Bulma.level}`}>
            <div className={`${Bulma.column} ${Bulma['has-text-centered']}`}>
              <p className={`${Bulma.title} ${Bulma['is-size-3']}`}>
                <span className={`${Bulma['has-text-primary']}`}>01 /</span><br /> Get Ahead
              </p>
              <p className={`${Bulma.subtitle} ${Bulma['is-size-5']}`}>
                Secure parking on your way to school or on arrival.
              </p>
            </div>
            <div className={`${Bulma.column} ${Bulma['has-text-centered']}`}>
              <p className={`${Bulma.title} ${Bulma['is-size-3']}`}>
                <span className={`${Bulma['has-text-primary']}`}>02 /</span><br /> Save Money
              </p>
              <p className={`${Bulma.subtitle} ${Bulma['is-size-5']}`}>
                ParKing is up to 70% less than school parking costs.
              </p>
            </div>
            <div className={`${Bulma.column} ${Bulma['has-text-centered']}`}>
              <p className={`${Bulma.title} ${Bulma['is-size-3']}`}>
                <span className={`${Bulma['has-text-primary']}`}>03 /</span><br /> Save Time
              </p>
              <p className={`${Bulma.subtitle} ${Bulma['is-size-5']}`}>
                Reserve it and go. No need to waste time looking for parking.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Figures */}
      <section className={`${Bulma.hero} ${Bulma['is-medium']} figureHero`} id='save'>
        <div className={Bulma['hero-body']}>
          <div className={Bulma.container}>
            <p style={{paddingBottom: '2rem'}} className={`${Bulma.title} ${Bulma['is-size-2']} ${Bulma['has-text-centered']}`}>By the numbers</p>
            <div ref={refNumbers} className={`${Bulma.columns} ${Bulma.level}`}>
              <div className={`${Bulma['level-item']} ${Bulma.column} ${Bulma['has-text-centered']}`}>
                <div>
                  <p className={`${Bulma.title} ${Bulma['is-size-1']}`}>${numbersInView && <CountUp end={600} />}</p>
                  <p className={`${Bulma.heading} ${Bulma['is-size-5']}`}>Average annual savings per school year</p>
                </div>
              </div>
              <div className={`${Bulma['level-item']} ${Bulma.column} ${Bulma['has-text-centered']}`}>
                <div>
                  <p className={`${Bulma.title} ${Bulma['is-size-1']}`}>${numbersInView && <CountUp end={1400} />}</p>
                  <p className={`${Bulma.heading} ${Bulma['is-size-5']}`}>Earned per parking space in a year</p>
                </div>
              </div>
            </div>
            <div className={`${Bulma.columns} ${Bulma['is-centered']} ${Bulma['is-vcentered']}`} style={{marginTop: '4em'}}>
              <p className={`${Bulma.column} ${Bulma['is-8']} ${Bulma.title} ${Bulma['is-size-2']} ${Bulma['has-text-centered']}`}>What people are saying</p>
              <div className={`${Bulma.box} ${Bulma.column} ${Bulma['is-4']}`} style={{ padding: '1.5rem' }}>
                <p className={`${Bulma['is-size-4']}`}>
                  Within a day of using ParKing, I wanted to know if I could book for the whole week.
                </p>
                <b>Josh</b>, Vancouver
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Schools */}
      <Schools logo={logo} />
      {/* How */}
      <section className={`${Bulma['is-medium']} ${Bulma.hero} ${Bulma['is-bold']}`} id='earn'>
        <div className={Bulma['hero-body']}>
          <div className={Bulma.container}>
            <p ref={refHow} style={{paddingBottom: '2rem'}} className={`${Bulma.title} ${Bulma['is-size-2']} ${Bulma['has-text-centered']}`}>
              Making money with <img className='copyLogo logoInTitle' src={logo} alt='ParKing' /> is <u>easy</u>
            </p>
            <div className={`${Bulma.columns} ${Bulma['has-text-centered']} ${Bulma['is-centered']}`}>
              <div className={`${A.animated} ${showHow ? A.slideInUp : ''} ${Bulma.column} ${Bulma['is-4']}`}>
                <img style={{transform: 'scale(1.15)'}} className='stepsImg' src={list} alt='list icon' />
                <p className={`${Bulma.subtitle} ${Bulma['is-size-4']}`}>List your parking space</p>
              </div>
              <div className={`${A.animated} ${showHow ? A.slideInUp : ''} ${Bulma.column} ${Bulma['is-4']}`}>
                <img className='stepsImg' src={approve} alt='approval graphic' />
                <p className={`${Bulma.subtitle} ${Bulma['is-size-4']}`}>Drivers book your space</p>
              </div>
              <div className={`${A.animated} ${showHow ? A.slideInUp : ''} ${Bulma.column} ${Bulma['is-4']}`}>
                <img className='stepsImg' src={money} alt='bills graphic' />
                <p className={`${Bulma.subtitle} ${Bulma['is-size-4']}`}>Money in the bank!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Register Interest */}
      <section className={`${Bulma.hero} ${Bulma['is-primary']} firstHero`} id='download'>
        <div className={`${Bulma['hero-body']}`}>
          <div className={Bulma.container} style={{padding: '4rem 1rem'}}>
            <div className={`${Bulma.columns} ${Bulma['is-centered']}`}>
              <div className={`${Bulma.column} ${Bulma['is-8']}`}>
                <form onSubmit={handleSubmit} className={Bulma['has-text-centered']}>
                  <p className={`${Bulma['title']} ${Bulma['is-size-2']} ${Bulma['is-spaced']}`}>
                    <span style={{ whiteSpace: 'no-wrap' }}><b>ParKing</b> is live in Vancouver</span> Register now!
                  </p>
                  <div className={Bulma.field}>
                    <p className={Bulma.control}>
                      <input type='text' className={Bulma.input} value={name} placeholder='Name' onChange={(e) => setName(e.target.value)} />
                    </p>
                  </div>
                  <div className={Bulma.field}>
                    <p className={Bulma.control}>
                      <input type='email' className={Bulma.input} value={email} placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
                    </p>
                  </div>
                  <div className={Bulma.field}>
                    <div className={Bulma.control} style={{fontSize: '1.2rem', lineHeight: '2em'}}>
                      <span>I want to </span>
                      <label className={`${LandingStyles.role} ${Bulma.radio}`}><input onChange={(e) => setRole(e.target.value)} value='park' type='radio' name='role' />Park</label>
                      <label className={`${LandingStyles.role} ${Bulma.radio}`}><input onChange={(e) => setRole(e.target.value)} value='host' type='radio' name='role' />Host</label>
                      <label className={`${LandingStyles.role} ${Bulma.radio}`}><input onChange={(e) => setRole(e.target.value)} value='both' type='radio' name='role' />Both!</label>
                      <button type='submit' className={`${Bulma['is-hidden-touch']} ${Bulma.button} ${Bulma['is-pulled-right']} ${Bulma['is-primary']} ${Bulma['is-light']} ${isLoading ? Bulma['is-loading'] : ''}`}>Sign Up</button>
                    </div>
                    <button type='submit' className={`${LandingStyles.yesBtn} ${Bulma['is-hidden-desktop']} ${Bulma.button} ${Bulma['is-primary']} ${Bulma['is-light']} ${isLoading ? Bulma['is-loading'] : ''}`}>Sign Up</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Landing;
