import React from 'react';
import M from '../styles/Modal.module.css';
import logo from '../img/crown.png';
import car from '../img/car2.png';
import parking from '../img/parking.png';

export default function WelcomeModal (props) {
  const selectedPark = () => props.toggleModal()
  const selectedRent = () => props.showListModal()
  return (
    <div className={`modal ${M.welcomeModal} museo ${props.active ? 'active' : ''}`}>
      <div className={`trovaBlueBG modal-container ${M.modalContainer}`}>
        <div className='modal-body'>
          <div className='content'>
            <div className={`${M.logoParent} container text-center`}>
              <div className='columns'>
                <div className='col-6 col-mx-auto'>
                  <img className={`img-responsive`} src={logo} alt='logo for ParKing' />
                  <p><small className={`${M.tagline}`}>park share</small></p>
                </div>
              </div>
            </div>
            <p className={`${M.byline}`}>
              <em>ParKing connects people with parking providing an affordable and safe space to leave your vehicle.</em>
            </p>
            <div className={`${M.dividerParent} container`}>
              <div className='columns'>
                <div onClick={selectedPark} className={`${M.iconParent} text-center column col-5`}>
                  <img className={`${M.icon}`} src={car} alt='car icon' />
                  <p>I am looking for parking</p>
                </div>
                <div className='divider-vert column col-2'></div>
                <div onClick={selectedRent} className={`${M.iconParent} text-center column col-5`}>
                  <img className={`${M.icon}`} src={parking} alt='parking sign' />
                  <p>I have a space for rent</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
