import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../lib/api.js';
import StartMessageModal from '../components/StartMessageModal';
import S from '../styles/Manage.module.css';

function ManageSpace({ user, location }) {
  let { spaceid } = useParams()
  const [ space, updateSpace ] = useState(null)
  const [ spaceBookings, setSpaceBookings ] = useState([])
  const [ showMessageModal, toggleMessageModal ] = useState(false)
  const [ reBooking, setReBooking ] = useState(null)

  function openMessageModal (booking) {
    setReBooking(booking)
    toggleMessageModal(true)
  }

  useEffect(() => {
    (async function loadData () {
      try {
        let [ space, spaceBookings ] = await Promise.all([
          await api('/spaces/getOne', { query: { _id: spaceid } }),
          await api('/bookings/get', { query: { space: spaceid }, sort: { sort: { startUnix: -1 } } })
        ])
        let usernames = await api('/bookings/getUsernames', { userids: spaceBookings.map(b => b.userid) })
        updateSpace(space)
        setSpaceBookings(spaceBookings.map(b => Object.assign(b, { username: usernames[b.userid] || b.userid })))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [spaceid])

  return (
    <div className='viewParent'>
      <div className='container'>
        <div className='columns' style={{ padding: '2rem' }}>
          <div className='column col-8 col-mx-auto col-sm-12'>
            {!space ? <div className='loading'></div> :

              <div className={`timeline ${S.timeline}`}>

                <div className='timeline-item'>
                  <div className='timeline-left'>
                    <span className='timeline-icon'></span>
                  </div>
                  <div className='timeline-content'>
                    <div className='tile'>
                      <div className='tile-content'>
                        <p className='tile-subtitle'>{space.posted}</p>
                        <p className='tile-title'>Space listed on ParKing</p>
                      </div>
                    </div>
                  </div>
                </div>

                {spaceBookings.map(b => (
                  <div className='timeline-item' key={b._id}>
                    <div className='timeline-left'>
                      <a className='timeline-icon icon-lg' href='#timeline-example-2'>
                        <i className='icon icon-check'></i>
                      </a>

                    </div>
                    <div className='timeline-content'>
                      <div className='tile'>
                        <div className='tile-content'>
                          <p className='tile-subtitle'>{b.date}</p>
                          <p className='tile-title'>
                            Space booked by <span className={`${S.username}`} onClick={() => openMessageModal(b)}>{b.username}</span>
                          </p>
                          <p className='tile-title'>{b.rate === 'daily' ? 'All day' : `${b.start.charAt(0) === '0' ? b.start.substring(1, b.start.length) : b.start}-${b.end}`} <span className='label label-success'>+${b.price}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {showMessageModal && <StartMessageModal user={user} booking={reBooking} space={space} toggleMessageModal={toggleMessageModal} />}

              </div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageSpace;
