import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import api from '../lib/api.js';
import auth from '../lib/auth.js';
import R from '../styles/Register.module.css';
import logo from '../img/crown.png';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: '',
      avatarLoading: false,
      uploadError: false,
      username: props.location.state ? props.location.state.name : '',
      email: props.location.state ? props.location.state.email : '',
      password: '',
      passwordConfirm: '',
      error: false,
      loading: false
    };
  }
  handleChange = (e) => this.setState({[e.target.name]: e.target.value})

  handleUpload = async (e, mode = 'files') => {
    try {
      this.setState({ avatarLoading: true })
      let formData = new FormData()
      formData.append('file', mode === 'files' ? e.target.files[0] : e)
      formData.append('fileName', `avatar_${Math.floor(Math.random() * 100000)}`)
      formData.append('publicKey', process.env.REACT_APP_IMAGEKIT_PUB_KEY)

      let { token, signature, expire } = await api('/auth/imagekit', {}, 'GET')

      formData.append('signature', signature || '')
      formData.append('expire', expire || 0)
      formData.append('token', token)
      let uploadResponse = await fetch('https://api.imagekit.io/v1/files/upload', {
        'method': 'POST',
        'Content-Type' : 'multipart/form-data',
        'Accept': 'application/json',
        'body': formData
      })
      let uploadResult = await uploadResponse.json()
      this.setState({ avatar: uploadResult.url })
    } catch (error) {
      console.error(error)
      this.setState({ uploadError: error.message })
    } finally {
      this.setState({ avatarLoading: false })
    }
  }

  submitRegistration = async (e) => {
    try {
      e.preventDefault()
      let { email, password, passwordConfirm, username, avatar } = this.state
      if (!email || !password || !passwordConfirm || !username) {
        this.setState({ error: 'Required fields are missing.', loading: false })
        return false
      }
      if (password !== passwordConfirm) {
        this.setState({ error: 'Passwords do not match' })
        return false
      }
      this.setState({ loading: true })
      let { token, user } = await this.register(email.trim().toLowerCase(), password, username, avatar)
      if (token) {
        auth.setToken(token)
        auth.setUserInfo(user)
        this.props.history.push('/map', { from: 'register' })
      }
    } catch (error) {
      this.setState({ error: error.message, loading: false })
    }
  }

  register = async (email, password, username, avatar) => {
    try {
      return api('/auth/register', { email, password, username, avatar })
    } catch (error) {
      console.error(error)
      this.setState({
        error: true,
        message: error.message || 'Registration failed. Please try again.'
      })
      return false
    }
  }

  render() {
    return (
      <div id='registerView' className={`${R.registerContainer} hero trovaBlueBG`}>
        <div className='columns'>
          <div className='column col-md-12 col-8 col-mx-auto'>
            <div className='container grid-lg'>
              <div>
                <div className={`card ${R.registerCard}`}>
                  <div className='card-body'>
                    <form className='form-group form-horizontal' onSubmit={this.submitRegistration}>
                      <div className='card-header'>
                        {
                          this.state.avatar ?
                            <img src={this.state.avatar} alt='Uploaded avatar' className={`${R.registerAvatar}`} /> :
                            <Link to='/welcome'>
                              <img src={logo} alt='ParKing logo' className={R.registerLogo} />
                            </Link>
                        }
                      </div>

                      <div className='form-group'>
                        <div className='col-3 col-sm-12'>
                          <label className='form-label' htmlFor='register-name'>Avatar</label>
                        </div>
                        <div className='col-8 col-sm-12 col-ml-auto'>
                          <input className={`${this.state.avatarLoading ? 'loading' : ''} ${R.registerInput} mt-2`} onChange={this.handleUpload} name='photo' type='file' />
                          {this.state.uploadError && <p>Failed to upload: {this.state.uploadError}</p>}
                        </div>
                      </div>

                      <div className='form-group'>
                        <div className='col-3 col-sm-12'>
                          <label className='form-label' htmlFor='register-username'>First Name</label>
                        </div>
                        <div className={`col-8 col-sm-12 col-ml-auto ${R.registerInputParent}`}>
                          <input className={R.registerInput} id='register-username' name='username' value={this.state.username} type='text' onChange={this.handleChange} />
                        </div>
                      </div>

                      <div className='form-group'>
                        <div className='col-3 col-sm-12'>
                          <label className='form-label' htmlFor='register-email'>Email</label>
                        </div>
                        <div className={`col-8 col-sm-12 col-ml-auto ${R.registerInputParent}`}>
                          <input className={R.registerInput} id='register-email' name='email' value={this.state.email} type='email' onChange={this.handleChange} />
                        </div>
                      </div>

                      <div className='form-group'>
                        <div className='col-3 col-sm-12'>
                          <label className='form-label' htmlFor='register-password'>Password</label>
                        </div>
                        <div className={`col-8 col-sm-12 col-ml-auto ${R.registerInputParent}`}>
                          <input className={R.registerInput} id='register-password' name='password' value={this.state.password} type='password' onChange={this.handleChange} />
                        </div>
                      </div>

                      <div className='form-group' style={{marginBottom: '2rem'}}>
                        <div className='col-3 col-sm-12'>
                          <label className='form-label' htmlFor='register-password2'>Confirm Password</label>
                        </div>
                        <div className={`col-8 col-sm-12 col-ml-auto ${R.registerInputParent}`}>
                          <input className={R.registerInput} id='register-password2' name='passwordConfirm' value={this.state.passwordConfirm} type='password' onChange={this.handleChange} />
                        </div>
                      </div>
                      {this.state.error && (
                        <div>
                          <p>Failed to register: {this.state.error}</p>
                        </div>
                      )}

                      <button type='submit' className={`btn btn-primary ${this.state.loading ? 'loading' : ''} ${R.registerBtn}`}>Sign Up</button>
                      <Link to='/login' className={`btn btn-link trovaBlue float-right ${R.toLoginBtn}`}>Login</Link>
                    </form>
                    {/* <div style={{ textAlign: 'center' }}>
                      By registering, you agree to ParKing's <a href='/Trova_ToS.pdf' target='_blank' rel='noopener noreferrer'>terms of service</a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Register;
