import React, { useState, useEffect } from 'react';
import api from '../lib/api';
import StripeConnect from '../components/partials/StripeConnect';
import PaymentMethod from '../components/partials/AddPaymentMethod';
import Tawk from '../components/partials/Tawk';
import placeholder from '../img/car-sq.png';
import { parseSettingsHash } from '../lib/parseHash';

function Settings ({ user, location }) {
  const [ view, setView ] = useState(parseSettingsHash(location.hash))
  const [ cardAdded, toggleCardAdded ] = useState(false)
  const [ updateCC, toggleUpdateCC ] = useState(false)
  const [ password, setPassword ] = useState('')
  const [ newp, setNewp ] = useState('')
  const [ confirmp, setConfirmP ] = useState('')
  const [ dashLink, setDashLink ] = useState('https://dashboard.stripe.com')
  useEffect(() => {
    setView(location.state && location.state.stripeConnected ? 'payments' : parseSettingsHash(location.hash))
  }, [location.hash, location.state])
  useEffect(() => {
    if (typeof user.stripe_data === 'string') {
      (async () => {
        try {
          let { url } = await api('/auth/stripedash', { account: user.stripe_data })
          setDashLink(url)
        } catch (error) {
          console.error(error)
        }
      })()
    }
  }, [ user.stripe_data ])
  const handleCardAdded = (pm) => {
    toggleCardAdded(pm)
  }
  const handlePWChange = ({ target }) => target.name === 'password' ? setPassword(target.value) : target.name === 'newp' ? setNewp(target.value) : setConfirmP(target.value)
  return (

    <div className='viewParent container'>
      <div className='settingsParent'>
        <div className='column col-10 col-sm-12 col-mx-auto'>
          <div className='panel settingsPanel'>
            <Tawk page='settings' />
            <div className='panel-header text-center'>
              <figure className='avatar avatar-lg'><img src={user.avatar || placeholder} alt='Avatar' /></figure>
              <div className='panel-title h5 mt-10'>{user.username}</div>
              <div className='panel-subtitle'>{user.email}</div>
            </div>
            <nav className='panel-nav'>
              <ul className='tab tab-block'>
                <li className={`tab-item ${view === 'payments' ? 'active' : ''}`}><a href='#payments'>Payments</a></li>
                <li className={`tab-item ${view === 'account' ? 'active' : ''}`}><a href='#account'>Account</a></li>
              </ul>
            </nav>

            {view === 'payments' ? (
              <div className='panel-body'>

                {!user.stripe_cus_id && !cardAdded ? (
                  <div className='tile tile-centered columns'>
                    <div className='tile-content column col-5 col-sm-12'>
                      <div className='tile-title text-bold'>Drivers: add a payment method</div>
                      <div className='tile-subtitle'>
                        Link a credit card to ParKing
                        <br />
                        <small>Adding a payment method is required to pay for parking</small>
                      </div>
                    </div>
                    <div className='tile-action column col-7 col-sm-12 cardInputParent'>
                      <PaymentMethod user={user} cardAdded={handleCardAdded} role='add' />
                    </div>
                  </div>
                ) : (
                  <div className='tile tile-centered columns'>
                    <div className='tile-content column col-5 col-sm-12'>
                      <div className='tile-title text-bold'>Active payment method</div>
                      <div className='tile-subtitle'>
                        {user.paymentMethod || cardAdded}
                        <br />
                        <small>This card will be charged when you book a parking space</small>
                      </div>
                    </div>
                    <div className={`tile-action column col-7 col-sm-12 cardInputParent ${!updateCC ? 'col-auto col-ml-auto ' : 'col-7'}`}>
                      {!updateCC ? <button onClick={() => toggleUpdateCC(true)} className='btn trovaBlue'>Update</button> : <PaymentMethod user={user} cardAdded={handleCardAdded} role='update' />}
                    </div>
                  </div>
                )}

                {!user.stripe_data ? (
                  <div className='tile tile-centered columns'>
                    <div className='tile-content column col-6 col-sm-12'>
                      <div className='tile-title text-bold'>Owners: receive payments on ParKing</div>
                      <div className='tile-subtitle'>You'll need your banking info to <u>receive payments for bookings</u>
                        <br />
                        <small>Your banking information is necessary to transfer funds from parkers to your bank account</small>
                      </div>
                    </div>
                    <div className='tile-action column col-auto col-sm-12 col-ml-auto cardInputParent'>
                      <StripeConnect user={user} />
                    </div>
                  </div>
                ) : (
                  <div className='tile tile-centered columns'>
                    <div className='tile-content column col-8 col-sm-12'>
                      <div className='tile-title text-bold'>Stripe Connected</div>
                      <div className='tile-subtitle'>You're ready to receive payments on ParKing</div>
                    </div>
                    <div className='tile-action column col-auto col-sm-12 col-ml-auto cardInputParent'>
                      <a target='_blank' rel='noopener noreferrer' href={dashLink} className='btn trovaBlue'>Stripe Dashboard</a>
                    </div>
                  </div>
                )}
                <div className='tile tile-centered columns'>
                  <div className='tile-content column col-12'>
                    <p>NB: You do not need to connect both a payment method and a bank account if you only want to use ParKing as either a Driver or an Owner</p>
                  </div>
                </div>
              </div>
            ) : (

              <div className='panel-body'>
                <div className='tile tile-centered columns'>

                  <div className='tile-content column col-8 col-mx-auto col-sm-12'>

                    <div className='tile-title text-bold mb-2'>Update password</div>

                    <div className='form-group'>
                      <input value={password} onChange={handlePWChange} name='password' className='form-input' type='password' id='old' placeholder='Old Password' />
                    </div>
                    <div className='form-group'>
                      <input value={newp} onChange={handlePWChange} name='newp' className='form-input' type='password' id='newp' placeholder='New Password' />
                    </div>
                    <div className='form-group'>
                      <input value={confirmp} onChange={handlePWChange} name='confirmp' className='form-input' type='password' id='confirmp' placeholder='Confirm Password' />
                    </div>
                  </div>
                </div>
                <div className='tile tile-centered columns'>
                  <div className='column col-4 col-ml-auto col-sm-12'>
                    <button className='btn btn-primary btn-block'>Update</button>
                  </div>
                </div>
              </div>

            )}

            {view === 'account' && <div className='panel-footer'>
              <a href='/logout' className='btn btn-primary btn-block'>Log Out</a>
            </div>}

          </div>
        </div>

      </div>

    </div>
  );
}

export default Settings;
